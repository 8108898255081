<template>
    <layout-vertical>
        <router-view />

        <template #navbar="{ toggleVerticalMenuActive }">
            <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </template>

        <template v-slot:breadcrumb>
            <app-breadcrumb />
        </template>

        <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue';
import Navbar from '../components/Navbar.vue';

export default {
    components: {
        // AppCustomizer,
        LayoutVertical,
        Navbar,
        AppBreadcrumb,
    },
    data() {
        return {
            // showCustomizer: $themeConfig.layout.customizer,
        };
    },
};
</script>

<style lang="scss">
.footer {
    opacity: 0 !important;
    z-index: -9 !important;
    visibility: hidden !important;
}

.app-content,
.content-wrapper,
.content-body,
.users-list,
.users-card {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
}
</style>
