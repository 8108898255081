export default [
    {
        title: 'menu.home',
        icon: 'HomeIcon',
        route: 'home',
        resourse: 'Home',
        action: 'read',
    },

    {
        title: 'menu.users',
        icon: 'UserIcon',
        route: 'users-list',
        resourse: 'Users',
        action: 'read',
    },

    {
        title: 'menu.bankcard',
        icon: 'CreditCardIcon',
        route: 'bankcard-list',
        resourse: 'Users',
        action: 'read',
    },

    {
        title: 'menu.gameItems',
        icon: 'GridIcon',
        resourse: 'GameItems',
        action: 'read',
        children: [
            {
                title: 'skins.title',
                icon: 'LayersIcon',
                children: [
                    {
                        title: 'skins.list',
                        route: 'skins-list',
                    },

                    {
                        title: 'skins.add',
                        route: 'skins-add',
                    },
                ],
            },

            {
                title: 'models.title',
                icon: 'BoxIcon',
                children: [
                    {
                        title: 'models.list',
                        route: 'models-list',
                    },

                    {
                        title: 'models.add',
                        route: 'models-add',
                    },
                ],
            },

            {
                title: 'characters.title',
                icon: 'UserIcon',
                children: [
                    {
                        title: 'characters.list',
                        route: 'characters-list',
                    },

                    {
                        title: 'characters.add',
                        route: 'characters-add',
                    },
                ],
            },

            {
                title: 'skybox.title',
                icon: 'CloudIcon',
                children: [
                    {
                        title: 'skybox.list',
                        route: 'skybox-list',
                    },

                    {
                        title: 'skybox.add',
                        route: 'skybox-add',
                    },
                ],
            },
        ],
    },

    {
        title: 'menu.statistics',
        icon: 'TrendingUpIcon',
        resourse: 'Statistics',
        action: 'read',
        children: [
            {
                title: 'menu.devices.title',
                icon: 'MonitorIcon',
                children: [
                    {
                        title: 'menu.devices.new',
                        route: 'statistics-new-devices',
                    },
                    {
                        title: 'menu.devices.active',
                        route: 'statistics-active-devices',
                    },
                ],
            },

            {
                title: 'menu.trials.title',
                icon: 'GiftIcon',
                children: [
                    {
                        title: 'menu.trials.dynamic',
                        route: 'statistics-trials-dynamic',
                    },
                    {
                        title: 'menu.trials.details',
                        route: 'statistics-trials',
                    },
                ],
            },

            {
                title: 'menu.subscriptions',
                icon: 'DollarSignIcon',
                route: 'statistics-subscriptions-dynamic',
            },

            {
                title: 'menu.cards',
                icon: 'CreditCardIcon',
                route: 'statistics-cards',
            },

            {
                title: 'menu.cancels',
                icon: 'ThumbsDownIcon',
                route: 'statistics-cancels',
            },
        ],
    },
];
